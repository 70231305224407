import React from 'react'
import Receipt from '../components/Receipt'
import StickyBarTop from '../components/StickyBarTop'
import StickyBarBottom from '../components/StickyBarBottom'
import OrderUnitItem from '../components/OrderUnitItem'
import DeliveryMethod from '../components/DeliveryMethod'
import LoadingIndicator from '../components/LoadingIndicator'
import PaymentPrompt from '../components/PaymentPrompt'
import DbService from '../services/db'
import ApiService from '../services/api'
import '../styles/Checkout.css'


class Checkout extends React.Component {

    constructor(props){
        super(props)
        this.dbService = new DbService()
        this.apiService = new ApiService()
        //
        this.onContactChange = this.onContactChange.bind(this)
        this.onAddressChange = this.onAddressChange.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.goBack = this.goBack.bind(this)

        this.addresses = this.dbService.getAddresses()

        this.TAG_EXPRESS = 'Express'
        this.TAG_SCHEDULED = 'Scheduled'

        // loading indicator 
        // FIXME: move this to an enum class
        this.BASKET_MOQ = 100
        this.state = {
            visibility: {
                progress: 'hidden',
                modal: ''
            },
            // to be generated <select> options
            addressOptions: null,
            contactOptions: null,
            selectedContact: null,
            receipt: {
                    value: this.dbService.getCartValue(),
                    fees: {
                        express: '-',
                        scheduled: '-'
                    },
                    delivery: 0, // delivery cost
                    tag: '' // delivery type
            },
            style: {
                deliveryMethod: {
                    express: '',
                    scheduled: ''
                }
            }
        }
    }

    componentDidMount(){
        this.fetchDeliveryFees()
    }

    onContactChange(e){
        e.preventDefault()
       
    }

    onAddressChange(e){
        e.preventDefault()
        this.fetchDeliveryFees()
    }

    fetchDeliveryFees(){
        if (this.refs.addresses){
            this.showLoadingIndicator()
            let cartValue = this.dbService.getCartValue()
            let addresses = this.dbService.getAddresses()
            let addressIndex = this.refs.addresses.value
            //
            this.apiService.fetchDeliveryFees(addresses[addressIndex].region, cartValue).then( (res) => {
                this.hideLoadingIndicator()

                //
                console.log(res.data)
                //
                this.resetDeliveryOptionHighlight()
                this.highlightDeliveryOption('express')
                //
                this.setState({
                    receipt: {
                        value: cartValue,
                        fees: res.data,
                        delivery: res.data.express,
                        tag: this.TAG_EXPRESS
                    }
                })

            }).catch( (err) => {
                console.log(err)
            })
        }

    }

    showLoadingIndicator(){
        this.setState({
            visibility: {
                progress: 'visible'
            }
        })
    }

    hideLoadingIndicator(){
        this.setState({
            visibility: {
                progress: 'hidden'
            }
        })
    }

    showPaymentModal(){
        this.setState({
            visibility: {
                modal: 'is-active'
            }
        })
    }

    handleClick(e) {
        e.preventDefault()
        let cartValue = this.dbService.getCartValue()

        if (cartValue >= this.BASKET_MOQ){

        
        this.showLoadingIndicator()
        let units = this.dbService.getCart()
        
        console.log(units)
        // get delivery type
        let customerId = this.dbService.getProfile().id
        let vendorId = this.dbService.getVendorId()


        this.apiService.createOrder(vendorId, customerId, units).then( (res) => {
            this.hideLoadingIndicator()

            //this.dbService.saveOrder(order)
            //
            this.dbService.clearCart()
            //
            console.log(res)
            let orderReference = res.data.fields['Reference']
            let summaryLink = '/summary/' + orderReference
            this.props.history.push(summaryLink)
        }).catch( (err) => {
            console.error(err)
        })
        }
        else {
            alert('Your minimum order should not be less than 100 Ksh.')
        }
    }

    goBack(){
        this.props.history.goBack()
    }

    resetDeliveryOptionHighlight(){
        let currentState = this.state
        currentState.style.deliveryMethod = {
            express: '',
            scheduled: ''
        }

        this.setState(currentState)
    }

    highlightDeliveryOption(option){
        let currentState = this.state
        if (option === 'express'){
            currentState.style.deliveryMethod.express = 'selected'
        }
        if (option === 'scheduled'){
            currentState.style.deliveryMethod.scheduled = 'selected'
        }
    }

    updateReceipt(deliveryCost, tag){
        let currentState = this.state
        currentState.receipt.delivery = parseInt(deliveryCost)
        currentState.receipt.tag = tag
        this.setState(currentState)
    }


    render(){
        
        let orderUnitRows = this.dbService.getCart().map( (value, index) => {
            return <OrderUnitItem key={value.productId} name={value.name} image={value.image} price={value.price} qty={value.quantity} />
        } )
        
        return (
            <div className='Checkout'>
                <LoadingIndicator visibility= { this.state.visibility.progress } /> 
                <StickyBarTop title='Checkout' onBackPress={this.goBack}/>
                <div className='Checkout-content'>
                    <div className='Checkout-units-header'>Items</div>
                    <div className='Checkout-units'>
                        { orderUnitRows }
                    </div>                    
                    <Receipt header='Receipt' payload={this.state.receipt} /> 
                   
                </div> 
                <PaymentPrompt amount={this.state.receipt.value} 
                                from={this.state.selectedContact}
                                visibility={this.state.visibility.modal}/> 
                <StickyBarBottom value='Order Now' onClick={this.handleClick}/>
            </div>
        );
    }
}

export default Checkout