import store from 'storejs'

class DbService {
    constructor(){
        
    }

    // save address
    saveAddress(address){
        if(!store.get('addresses')){
            let addresses = []
            store.set('addresses', addresses)
        }

        let currentAddresses = store.get('addresses')
        currentAddresses.push(address)
        store.set('addresses', currentAddresses)
    } 

    // update contact
    updateAddress(index, address){

        let addresses = store.get('addresses')
        addresses[index] = address
        store.set('addresses', addresses)
        console.log('Address updated successfully')
    }

    // get addresses
    getAddresses(){
        let addresses = store.get('addresses')
        if (!addresses){
            return []
        }
        else {
            return addresses
        }
    }

    // get address details
    getAddressDetails(index){
        let addresses = store.get('addresses')
        if (!addresses){
            return null
        }
        else {
            return addresses[index]
        }
    }

    // save contat
    saveContact(contact){
        if(!store.get('contacts')){
            let contacts = []
            store.set('contacts', contacts)
        }

        let currentContacts = store.get('contacts')
        currentContacts.push(contact)
        store.set('contacts', currentContacts)
    }

    // update contact
    updateContact(index, contact){

        let contacts = store.get('contacts')
        contacts[index] = contact
        store.set('contacts', contacts)
        console.log('Contact updated successfully')
    }

    //
    // get contacts
    getContacts(){
        let contacts = store.get('contacts')
        if (!contacts){
            return []
        }
        else {
            return contacts
        }
    }

    // get contact details
    getContactDetails(index){
        let contacts = store.get('contacts')
        if (!contacts){
            return null
        }
        else {
            return contacts[index]
        }
    }

    // get orders

    getOrders(){
        let orders = store.get('orders')
        if (!orders){
            return []
        }
        else {
            // reverse
            return orders.reverse()
        }
    }

    // save order 
    saveOrder(order){
        if(!store.get('orders')){
            let orders = []
            store.set('orders', orders)
        }

        let currentOrders = store.get('orders')
        currentOrders.push(order)
        store.set('orders', currentOrders)
    }
 
    //
    initCart(){

        if (!store.get('cart')){
            let data = {
                units : [],
                total: 0
            }
            store.set('cart', data)
        }
    }

    // add to cart + increase qty
    addToCart(unit){

        // productId
        // quantity
        // price
        
        let cart = store.get('cart')
        let units = store.get('cart').units
        // check if product exist
        const exist = (element) => element.id == unit.id

        const productIndex = units.findIndex(exist)

        if (productIndex === -1){
            units.push(unit)
        }
        else {
            if (unit.quantity >= 0) {
                units[productIndex].quantity += unit.quantity
            }
            else {
                if (units[productIndex].quantity != 0){
                    units[productIndex].quantity += unit.quantity
                }
            }
        }
        cart.units = units

        let totalValue = units.map( (value, index) => {
            return value.price * value.quantity
        }).reduce( (a, b) => {
            return a + b
        })

        cart.total = totalValue
        
        // add to db
        store.set('cart', cart)
    
    }

    populate(units){

        let totalValue = units.map( (value, index) => {
            return value.price * value.quantity
        }).reduce( (a, b) => {
            return a + b
        })
        store.set('cart', {
            units: units,
            total: totalValue
        })

    }

    getCart(){
        if (store.get('cart')){
            // remove units with qty = 0
            let cart = []
            let units = store.get('cart').units
            units.forEach(element => {
                if (element.quantity != 0){
                    cart.push(element)
                }
            });

            return cart
        }
        else {
            return []
        }
    }

    getCartValue(){
        if (store.get('cart')){
            return store.get('cart').total
        }
        else {
            return 0
        }
    }

    getQty(productId) {
        if(store.get('cart')){
            const product = store.get('cart').units.find(product => product.id === productId)
            if (product){
                return product.quantity
            }
            else{
                return 0
            }
        }
        else {
            return 0
        }
    }

    clearCart(){
        store.set('cart', {
            units: [],
            total: 0
        })
    }

    initCache(){
       let data = { 
           productDetails: null
       }

       if (!store.get('cache')){
           store.set('cache', data)
       }
    }

    getCache(key){
        let collection = null
        const cache = store.get('cache') 
        if (cache){
            collection = cache[key]
        }
        return collection
    }

    setCache(key, value){
        const cache = store.get('cache')
        cache[key] = value
        store.set('cache', cache)
    }

    isCached(key){
        return store.get('cache')[key] !== null
    }

    setAffiliate(code){
        // if not null -> change
        // if null, don't change
        if(!store.get('affiliate')){
            if (!code){
                store.set('affiliate', null)
            }
            else {
                store.set('affiliate', code)
            }
        } 
        else {
            if (code){
                store.set('affiliate', code)
            }
        } 
    }

    getAffiliate(){
       return store.get('affiliate')
    }

    resetAffiliate(){
        if (store.get('affiliate')){
            store.set('affiliate', null)
        }
    }

    setPromotion(id){
        store.set('promocode', id)
    }

    getPromotion(){
        if (store.get('promocode')){
            return [store.get('promocode')]
        }
        else {
            return null
        }
    }
    
    resetPromotion(){
        if (store.get('promocode')){
            store.set('promocode', null)
        }
    }

    setPricingScheme(scheme){
        store.set('scheme', scheme)
    }

    getPricingScheme(){
        if (store.get('scheme')){
            return store.get('scheme')
        }
        else {
            return null
        }
    }

    getAffiliateLink(){
        if (store.get('link')){
            return store.get('link')
        }
        else {
            return null
        }
    }

    setAffiliateLink(link){
        store.set('link', link)
    }

    // 

    async initProfile(data){
        if (!store.get('profile')){
            store.set('profile', data)
        }
     }

     getProfile(){
         return store.get('profile')
     }

     setVendorId(id){
        if (!store.get('vendorId')){
            store.set('vendorId', id)
        }
     }

     getVendorId(){
         return store.get('vendorId')
     }
}


export default DbService