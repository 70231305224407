import React from 'react'
import '../styles/VendorItem.css'

class VendorItem extends React.Component {

    constructor(props){
        super(props)

        this.delivery = ''

        if (this.props.vendor.delivery == 0){
            this.delivery = 'Free Delivery'
        }
        else {
            this.delivery = this.props.vendor.delivery + ' Ksh'
        }
    }

    render(){
        return(
            <div className='VendorItem' onClick={this.props.onClick}>
                <div className='VendorItem-content'>
                    <div className='VendorItem-cover'>
                        <img src={this.props.vendor.cover} alt=""/>
                    </div>
                    <div className='VendorItem-name'>
                        {this.props.vendor.name}
                    </div>
                    <div className='VendorItem-details  '>
                        <div className='columns is-mobile is-gapless'>
                            <div className='column is-6'> 
                                <span className='VendorItem-location'>
                                    { this.props.vendor.location }
                                </span>
                            </div>
                            <div className='column is-6 has-text-right'>
                               <span className='VendorItem-delivery'>
                                    {this.delivery}
                               </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default VendorItem