import axios from 'axios'
import Config from '../config'

class ApiService{

    constructor(){
        this.config = new Config().get()
    }

    async getPromotionId(code){
        let endpoint = '/promotions'
        let payload = {
            code: code
        }

        let res = await axios.post(this.config.authUrl + endpoint, payload)
        return res.data
    }

    async getFeed(){
        let endpoint = '/feed'
        let res = await axios.get(this.config.authUrl + endpoint)
        return res.data
    }

    async getCategories(){
        let endpoint = '/categories'
        let res = await axios.get(this.config.authUrl + endpoint)
        return res.data
    }

    async getBrands(){
        let endpoint = '/brands'
        let res = await axios.get(this.config.authUrl + endpoint)
        return res.data
    }

    async getProducts(id){
        let endpoint = '/marketplace/vendors/' + id
        let res = await axios.get(this.config.authUrl + endpoint)
        return res.data
    }

    async getProductDetails(productId){
        let endpoint = '/products/' + productId
        let res = await axios.get(this.config.authUrl + endpoint)
        return res.data
    }

    async getOrders(){
        let endpoint = '/orders'
        let res = await axios.post(this.config.authUrl + endpoint)
        return res.data
    }

    async getOrderDetails(orderId){
        let endpoint = '/orders/' + orderId
        let res = await axios.post(this.config.authUrl + endpoint)
        console.log(res.data)
        return res.data
    }

    async createOrder(vendorId, customerId, units){

        // transform
        let orderUnits = units.map( (value, index) => {
            return {
                'id': value.id,
                'quantity': value.quantity
            }
        })

        // save
        let endpoint = '/marketplace/vendors/' + vendorId + '/orders/create'

        let payload = {
            "vendor_id": vendorId,
            "customer": customerId,
            "units": orderUnits
        }

        console.log(payload)
        
        let res = await axios.post(this.config.authUrl + endpoint, payload)
        return res.data
    }

    async createAffiliateLink(name, number, region){
        let endpoint = '/affiliates/create'
        let payload = {
            name: name,
            number: number,
            region: region
        }

        let res = await axios.post(this.config.authUrl + endpoint, payload)
        return res.data
    }

    async fetchDeliveryFees(region, basketSize){
        let endpoint = '/deliveries/fees'
        let payload = {
            region: region,
            basket: basketSize
        }

        let res = await axios.post(this.config.authUrl + endpoint, payload)
        return res.data
    }

    async fetchSubscriptionDetails(subscriptionId){
        let endpoint = '/subscriptions/' + subscriptionId
        let payload={}
        let res = await axios.post(this.config.authUrl + endpoint, payload)
        return res.data
    }

    async fetchVendors(){
        let endpoint = '/marketplace/vendors'
        let res = await axios.get(this.config.authUrl + endpoint)
        return res.data
    }

}

export default ApiService