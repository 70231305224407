import React from 'react'
import '../styles/Login.css'
import PhoneInput from './PhoneInput'
import BigButton from './BigButton'
//
import AuthService from '../services/auth'
import DbService from '../services/db'
//
import Validator from '../utils/validator'

class Login extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            phoneNumber: '',
            validation: 'valid'
        }

        //
        this.updatePhoneNumber = this.updatePhoneNumber.bind(this)
        this.updatePin = this.updatePin.bind(this)
        // 
        this.handleClick = this.handleClick.bind(this)
        this.authService = new AuthService()
        this.dbService = new DbService()
        this.validator = new Validator()
    }

    updatePhoneNumber(e){
        e.preventDefault()
        this.setState({
            'phoneNumber': e.target.value
        })
    }

    updatePin(e){
        e.preventDefault()
        this.setState({
            'pin': e.target.value
        })
    }

    async handleClick(e) {
       e.preventDefault()
       // validate phone number format
       let isValid = this.validator.isPhoneNumber(this.state.phoneNumber)
       if (!isValid){
           // FIXME: replace with css validation error
           alert('Please enter a valid phone number')
       }
       else {
           let phoneNumber = this.state.phoneNumber
           let pin = this.state.pin

           if (phoneNumber.startsWith('0')){
               phoneNumber = '254'.concat(phoneNumber.substring(1))
           }
           //
          this.authService.login(phoneNumber, pin).then((res) => {

                this.dbService.initProfile(res.data).then( () => {
                    window.location.href = '/'
                })
                

           }).catch( (err) => {

               alert('Incorrect phone number or password')
           })
       }
    }


    render() {
        return (
            <div className='Login'>
                <div className='Login-content'>
                    <div className='Login-description'>
                        Please signin below with your phone number and pin
                    </div>
                    <PhoneInput placeholder='Phone number' 
                                value= {this.state.phoneNumber} 
                                onChange={this.updatePhoneNumber} />
                    
                    <input className='Login-pin' type="password" placeholder="PIN"
                                pattern="[0-9]*" inputMode="numeric" maxLength='4'
                                onChange={this.updatePin}/>
                    
                    <BigButton name='Continue' onClick={this.handleClick}/>
                </div>
            </div>
        );
    }
}

export default Login