import axios from 'axios'
import Config from '../config'

class AuthService {
    constructor(){
        this.config = new Config().get()
    }
    
    async login(phoneNumber, pin) {
    
        // post /marketplace/login

        const endpoint = '/marketplace/login'

        let payload = {
            'phone_number': phoneNumber,
            'pin': pin
        }
        let res = await axios.post(this.config.authUrl + endpoint, payload)
        let data = res.data
        return data
    }

    logout(){
        // TODO: remove id/token from storage
        return null
    }

}



export default AuthService