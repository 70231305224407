import React from 'react'
import '../styles/StickyMenuBottom.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagic , faShoppingCart, faUser, faArchive} from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom'

class StickyMenuBottom extends React.Component{

    constructor(props){
        super(props)
        this.iconInit = 'icon-init'
        this.iconHighlight = 'icon-highlight'
        //
        this.initHighlights = {
            home: this.iconInit,
            basket: this.iconInit,
            orders: this.iconInit,
            account: this.iconInit
        }
        
        //

        this.state = {
            highlights: this.highlight(this.props.highlight)
        }

        this.urls = {
            home: '/',
            basket: '/basket',
            orders: '/orders',
            account: '/account'
        }

    }

    highlight(key){
        let result = this.initHighlights
        Object.keys(this.initHighlights).forEach( (item) => {
            if (item == key){
                result[key] = this.iconHighlight
            }
        })

        return result
    }

    navigate(page, key){
        this.setState({
            highlights: this.highlight(key)
        })
        // navigate to page
        this.props.history.push(page)
    }

    render(){
        let highlights = this.state.highlights

        return(
            <div className='StickyMenuBottom'>
                 <div className="columns is-mobile">
                    <div className="column is-one-quarter">
                        <button onClick={ () => this.navigate(this.urls.home) } >
                            <FontAwesomeIcon className={highlights.home} icon={faMagic} />
                            <div className='text'>Explore</div>
                        </button>
                    </div>
                    <div className="column is-one-quarter">
                        <button onClick={ () => this.navigate(this.urls.basket) } >
                            <FontAwesomeIcon className={highlights.basket} icon={faShoppingCart} />
                            <div className='text'>Cart</div>
                        </button>
                    </div>
                    
                    <div className="column is-one-quarter">
                        <button onClick={ () => this.navigate(this.urls.orders) } >
                            <FontAwesomeIcon className={highlights.orders} icon={faArchive} />
                            <div className='text'>Orders</div>
                        </button>
                    </div>
    
                    <div className="column is-one-quarter">
                        <button onClick={ () => this.navigate(this.urls.account) } >
                            <FontAwesomeIcon className={highlights.account} icon={faUser} />
                            <div className='text'>Account</div>
                        </button>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default withRouter(StickyMenuBottom)