import React from 'react'

import StickyMenuTop from '../components/StickyMenuTop'
import StickyMenuBottom from '../components/StickyMenuBottom'

// 
import VendorItem from '../components/VendorItem'

//
import ApiService from '../services/api'
import DbService from '../services/db'
//

import '../styles/Home.css'

class Home extends React.Component{
    constructor(props){
        super(props)
        this.apiService = new ApiService()
        this.dbService = new DbService()
        this.INIT_CART_VALUE = 0
        this.state = {
            rows: null
        }
    }

    componentDidMount(){
        // init cache
        this.dbService.initCache()
        // init cart 
        this.dbService.initCart()
        //
        this.apiService.fetchVendors().then( (res) => {
            this.setState({
                rows: res.data
            })
        })
    }

    onClick(id){
        this.dbService.setVendorId(id)
        this.props.history.push('/vendors/' + id)
    }
        

    render(){
        let rows = this.state.rows
        if (rows != null ){
            this.vendorsList = rows.map( (value, index) => {
                // check if product exist
                return ( 
                         <VendorItem vendor={value} key={index} onClick={() => this.onClick(value.id)}/> 
                )
            })
        }
        else {
            // show empty state
        }
        
        return (

            <div className='Home'>
                <StickyMenuTop count={toString(this.INIT_CART_VALUE)}/>
                {this.vendorsList}
                <StickyMenuBottom highlight='home'/> 
            </div>
        );
    }
}

export default Home