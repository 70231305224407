import React from 'react'
import StickyBarTop from '../components/StickyBarTop'
import BasketItem from '../components/BasketItem'
import StickyBarBottom from '../components/StickyBarBottom'
import StickyMenuBottom from '../components/StickyMenuBottom'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import DbService from '../services/db'
import ApiService from '../services/api'

import '../styles/Basket.css'
import EmptyBasketIcon from '../images/basket-empty.png'

class Basket extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            rows: null,
            basketRows: null
        }
        this.dbService = new DbService()
        this.apiService = new ApiService()

        this.handleClick = this.handleClick.bind(this)
        this.goBack = this.goBack.bind(this)
        this.clearCart = this.clearCart.bind(this)
        this.BASKET_MOQ = 100
    }

    handleClick(e){
        e.preventDefault()
        let cartValue = this.dbService.getCartValue()
        if (cartValue >= this.BASKET_MOQ){
            this.props.history.push('/checkout')
        }
        else {
            alert('Your minimum order should not be less than 100 Ksh.')
        }
        
    }

    clearCart(e){
        e.preventDefault()
        this.dbService.clearCart()
        this.setState({
            basketRows: null
        })
    }

    navigate(path){
        this.props.history.push(path)
    }

    goBack(e){
        e.preventDefault()
        this.props.history.goBack()
    }

    render(){
        let cart = this.dbService.getCart()

        if (cart.length !== 0){
            // conditional rendering
            this.state.basketRows = cart.map((value, index ) => {
                    if (value.quantity !== 0 ){
                        return <BasketItem product={value} key={value.productId} key={index}/>
                    }
            })
            
            return (

                <div className='Basket'>
                    <StickyBarTop title='Basket' icon={faTrash} onClick={this.clearCart} onBackPress={this.goBack}/>
                    
                    <div className='Basket-content'>
                        {this.state.basketRows}
                    </div>
                    <StickyBarBottom value='Continue' onClick={this.handleClick}/>
                </div>
            );
        }
        else {
            return (
                <div className='EmptyBasket'>
                    <StickyBarTop title='Basket' onBackPress={this.goBack}/>
                    <div className='EmptyBasket-content'>
                        <img src={EmptyBasketIcon} />
                        <div className='EmptyBasket-message'>
                            Your basket is empty
                        </div>
                        <div className='EmptyBasket-browse'>
                            <button onClick={ () => this.navigate('/') }>
                                + Add Products 
                            </button>
                        </div>
                    </div>
                    <StickyMenuBottom highlight='basket'/>
                </div>
            );
        }
    } 
}

export default Basket